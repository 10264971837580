import React from 'react';
import FirstSection from './components/first_section';
// import { useRef } from 'react';
// import SecondSection from './components/second_section';

function App() {
  // const formSectionRef = useRef(null);

  // const scrollToForm = () => {
  //   formSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  // };

  return (
    <div className="md:min-h-screen"
    style={{
      background: "linear-gradient(355deg, rgba(27,27,27,1) 0%, rgba(20,20,20,1) 28%, rgba(15,15,15,1) 100%)"
    }}
    >
    {/* <div className="bg-[#252525] md:min-h-screen"> */}
      <FirstSection />

      {/* Form Section */}
      {/* <div ref={formSectionRef} className="flex justify-center p-8">
        <SecondSection />
      </div> */}
    </div>
  );
}

export default App;