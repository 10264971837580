import React, {useEffect} from 'react';
import promoImage from '../assets/promo2.jpeg';
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

function FirstSection() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex justify-center items-center md:min-h-screen m-auto px-[5%] md:px-0" style={{ maxWidth: "900px" }}>
      <div className="flex flex-col md:flex-row w-full md:h-screen max-w-7xl gap-2">
        
        {/* Left Side: Image */}
        <div className="bg-contain bg-center bg-top bg-no-repeat my-[5%] flex justify-center flex-col items-center" >
              <img 
                src={promoImage} 
                data-aos="fade-up" 
                data-aos-duration="2000"
                alt="promo" 
                className='w-full' 
              />
        </div>

        {/* Right Side: White Box */}
        <div className="flex flex-col justify-center items-center py-[5%] h-full">
          <div 
            className='p-8 bg-white flex flex-col justify-center w-full h-full' 
            data-aos="fade-down"
            data-aos-duration="2000"
          >
            <h1 className="text-4xl mb-4 text-[#202a54]">Masterclass de Parrilla & Cata de vinos</h1>
            <p className="text-lg mb-6 text-[#202a54]">¡Aprovecha últimos días de preventa!</p>
            <a target='_blank' rel="noreferrer" className='text-white py-2 text-center px-6 bg-[#151b36] transition duration-300 ease-in-out hover:bg-white hover:text-[#151b36] border border-[#151b36] font-[Open_Sans]' href="https://wa.me/529992741914">
              ¡Reserva tu lugar!
            </a>
          </div>
        </div>

      </div>
    </div>
  );
}

export default FirstSection;